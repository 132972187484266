import { useContext, useEffect, useState } from "react";
import { TeamsFxContext } from "./Context";
import FoldersBreadcrumb from './browser/FoldersBreadcrumb';
import FolderContent from "./browser/FolderContentView";
import FolderToolbar from "./browser/FolderToolbar";
import BrowserDrawer from "./browser/BrowserDrawer";
import { ErrorBoundary } from "react-error-boundary";

import {
  app
} from "@microsoft/teams-js";

import {
  Divider,
  makeStyles,
  tokens,
  shorthands,
  Dropdown,
  Option
} from "@fluentui/react-components";

import FolderContextProvider, { FolderContext } from "../context/FolderContextProvider";
import { FolderContextType } from "../types/Folder";
import { WebDAVContext } from "../context/WebDAVContext";
import { BrowserErrorMessage } from "./BrowserErrorMessage";
import { DocumentViewer } from "./viewer/DocumentViewer";
import { DocumentViewer2 } from "./viewer/DocumentViewer2";

const useStyles = makeStyles({
  container: {
    backgroundColor: tokens.colorNeutralBackground1,
    ...shorthands.padding("1em"),
    width: "100%"
  },
  root: {
    ...shorthands.overflow("hidden"),
    display: "flex",
    height: "100%"
  },
  content: {
    backgroundColor: tokens.colorNeutralBackground1,
    ...shorthands.flex(1),
    ...shorthands.padding("16px"),
    display: "flex",
    alignItems: "flex-start",
    ...shorthands.overflow("scroll")
  },
});

type FolderPaneProps = {
  rootPath: string | null
  initialPath: string | null
};

const personBrowserTabKey = "pbt_";
const currentRootFolderKey = personBrowserTabKey + "_root";

export default function PersonalBrowserTab() {

  const styles = useStyles();
  const { themeString } = useContext(TeamsFxContext);
  const [rootPath, setRootPath] = useState<string | null>(null);
  const [initialPath, setInitialPath] = useState<string | null>(null);

  /*
   * Another root folder is selected in the drawer component, we no longer
   * need the initial path.
   */
  const onRootFolderSelected = (path: string) => {
    setInitialPath(null);
    setRootPath(path);
    localStorage.setItem(currentRootFolderKey, path);
  }

  /*
   * Check if we are entering this page from a link in a chat.
   */
  useEffect(() => {
    app.initialize().then(async () => {
      const context = await app.getContext();
      const pathFromContext = context.page.subPageId;
      if (pathFromContext) {

        /**
         * Initialize the page from the context
         */
        setInitialPath(pathFromContext);
        const rootPathFromContext = pathFromContext.substring(0, pathFromContext.indexOf("/", "/OS".length + 1));
        setRootPath(rootPathFromContext);
        localStorage.setItem(currentRootFolderKey, rootPathFromContext);

        console.log("The path in the context is ", pathFromContext);
        console.log("The root path in the context is ", rootPathFromContext);
      } else {

        /**
         * Initialize the page from the store state, if any
         */
        const storedRootFolder = localStorage.getItem(currentRootFolderKey);
        if (storedRootFolder) {
          setRootPath(storedRootFolder);
        }

        console.log("No path in context")
      }
    })

  }, []);

  const { initializeClient } = useContext(WebDAVContext);

  return (
    <div className={`${styles.root} ${themeString === "default" ? "light" : themeString === "dark" ? "dark" : "contrast"}`}>
      <ErrorBoundary FallbackComponent={BrowserErrorMessage}
        onReset={initializeClient}
      >
        <BrowserDrawer onRootFolderSelected={onRootFolderSelected} rootFolder={rootPath} />
        <div className={styles.content}>
          <FolderContextProvider>
            <FolderPane rootPath={rootPath} initialPath={initialPath} />
          </FolderContextProvider>
        </div>
      </ErrorBoundary>
    </div>
  );
}

export function FolderPane({ rootPath, initialPath }: FolderPaneProps) {

  const { folder, viewerDocument, setFolderPath } = useContext(FolderContext) as FolderContextType;

  /**
   * Viewer stuff
   */
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [viewer, setViewer] = useState("Viewer 1");

  const styles = useStyles();

  /*
   * If the initial path is set, we use that value. Otherwise the stored state
   * coupled to the rootPath is used. If there is no state yet, then the rootPath
   * is used.
   */
  useEffect(() => {
    if (initialPath) {
      setFolderPath(initialPath);
    } else if (rootPath) {
      const storedPath = localStorage.getItem(personBrowserTabKey + rootPath)
      if (storedPath) {
        setFolderPath(storedPath);
      } else {
        setFolderPath(rootPath);
      }
    }
  }, [initialPath, rootPath]);

  /*
   * Keep track of the current path of each rootfolder
   */
  useEffect(() => {
    if (rootPath) {
      localStorage.setItem(personBrowserTabKey + rootPath, folder.path);
    }
  }, [folder]);

  /**
   * Open document in viewer if viewer document is set by the FolderContext 
   * provider.
   */
  useEffect(() => {
    if (viewerDocument) {
      setIsViewerOpen(true);
    }
  }, [viewerDocument]);

  return (
    <div className={styles.container}>
      <FolderToolbar />
      <Divider appearance="strong" />
      {
        (rootPath ? (
          <>
            <FoldersBreadcrumb rootPath={rootPath} />
            <FolderContent />
            <Dropdown
              defaultSelectedOptions = {["Viewer 1"]}
              onOptionSelect={ (_, data) => setViewer(data.optionText)}
              placeholder="Select an viewer"
            >
              <Option value="Viewer 1">apryse.com</Option>
              <Option value="Viewer 2">pspdfkit.com</Option>
            </Dropdown>
            { (viewer === "Viewer 1") ? (
              <DocumentViewer isOpen={isViewerOpen} closeViewer={() => setIsViewerOpen(false)} file={viewerDocument} />) : (
              <DocumentViewer2 isOpen={isViewerOpen} closeViewer={() => setIsViewerOpen(false)} file={viewerDocument} />)
            }
          </>
        ) : (
          null
        ))
      }
    </div>
  );
}