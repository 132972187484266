import { useEffect, useState } from 'react';

import {
    Dialog,
    DialogTrigger,
    DialogSurface,
    DialogTitle,
    DialogBody,
    DialogActions,
    DialogContent,
    Button,
    useId,
    Label,
    Input,
    InputProps,
    makeStyles,
} from "@fluentui/react-components";

interface NewChatDialogProps {
    isOpen: boolean;
    defaultValue: string,
    onClose: () => void;
    onStart: (name: string) => void;
}

const useStyles = makeStyles({
    content: {
        display: "flex",
        flexDirection: "column",
        rowGap: "10px",
    },
});

export default function NewChatDialog({ isOpen, defaultValue, onStart, onClose }: NewChatDialogProps) {

    const styles = useStyles();

    const [open, setOpen] = useState(false);
    const inputId = useId("input");
    const [value, setValue] = useState(defaultValue);
    const [startButtonEnabled, setStartButtonEnabled] = useState(false);

    useEffect(() => {
        setOpen(isOpen);
    }, [isOpen]);

    /**
     * Controls the default value
     */
    useEffect(() => {
        setValue(defaultValue);
    }, [defaultValue]);

    /**
     * Controls the start button
     */
    useEffect(() => {
        setStartButtonEnabled(value !== "");
    }, [value]);

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        onStart(value);
        onClose();
    }

    const onChange: InputProps["onChange"] = (_, data) => {
        setValue(data.value);
    };

    return (
        <Dialog open={open}
            onOpenChange={(event, data) => {
                if (!data.open) {
                    onClose();
                }
                setOpen(data.open);
            }}>
            <DialogSurface>
                <form onSubmit={handleSubmit}>
                    <DialogBody>
                        <DialogTitle>Nieuwe chat starten</DialogTitle>
                        <DialogContent className={styles.content}>
                            <Label required htmlFor={inputId}>
                                Titel van de chat:
                            </Label>
                            <Input required type='text' value={value} onChange={onChange} id={inputId} autoFocus={true} />
                        </DialogContent>
                        <DialogActions>
                            <DialogTrigger disableButtonEnhancement>
                                <Button appearance="secondary"
                                    onClick={onClose}
                                >Close</Button>
                            </DialogTrigger>
                            <Button type="submit" appearance="primary" disabled={!startButtonEnabled}>Starten</Button>
                        </DialogActions>
                    </DialogBody>
                </form>
            </DialogSurface>
        </Dialog>
    );
}