import { useContext, useEffect, useState } from 'react';

import {
    Dialog,
    DialogTrigger,
    DialogSurface,
    DialogTitle,
    DialogBody,
    DialogActions,
    DialogContent,
    Button,
    Label,
    Input,
    makeStyles,
    useId,
    InputProps,
    MessageBar,
    MessageBarBody,
    MessageBarTitle,
    MessageBarActions,
    MessageBarGroup
} from "@fluentui/react-components";

import { DismissRegular } from "@fluentui/react-icons";
import { FolderContext } from '../../context/FolderContextProvider';
import { FolderContextType } from '../../types/Folder';

interface AddFolderDialogProps {
    isOpen: boolean;
    onClose: () => void;
}

const useStyles = makeStyles({
    content: {
        display: "flex",
        flexDirection: "column",
        rowGap: "10px",
    },
});

type DialogMessageProps = {
    title: string
    message: string
    visible: boolean
    onVisibleChange: (visible: boolean) => void;
}

export default function AddFolderDialog({ isOpen, onClose }: AddFolderDialogProps) {

    const styles = useStyles();

    const [open, setOpen] = useState(false);

    const { folder, createFolder, setFolderPath } = useContext(FolderContext) as FolderContextType;

    const inputId = useId("input");
    const [value, setValue] = useState("");
    const [showInvalidInputMessage, setShowInvalidInputMessage] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const [addButtonEnabled, setAddButtonEnabled] = useState(false);

    useEffect(() => {
        setOpen(isOpen);
    }, [isOpen]);

    useEffect(() => {
        if (open) {
            const inputElement = document.querySelector(`input[id='${inputId}']`) as HTMLInputElement;
            if (inputElement) {
                inputElement.focus();
            }
        }

    }, [open])
    /**
     * Controls the add button
     */
    useEffect(() => {
        setAddButtonEnabled(value !== "");
    }, [value]);

    /**
     * Validate the user input. Shows an error message when an invalid character
     * is typed. The error message is hidden when another character is typed.
     * 
     * @param data the user input
     */
    const onChange: InputProps["onChange"] = (_, data) => {
        const invalidCharsRegex = /[*\\:<>|?"/]/;
        if (!invalidCharsRegex.test(data.value)) {
            setValue(data.value);
            setShowErrorMessage(false);
            setShowInvalidInputMessage(false);
        } else {
            setShowInvalidInputMessage(true);
        }
    };

    /**
     * Creates a new folder when the form is submitted.
     * 
     * @param event 
     */
    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        const result = await createFolder(folder.path, value)
        if (result.response === 'ok') {
            setShowErrorMessage(false);
            setFolderPath(folder.path);
            closeDialog();
        } else {
            if (result.response === 'exists') {
                setErrorMessage("er bestaat al een map met deze naam");
            } else if (result.response === 'error') {
                setErrorMessage(result?.message);
            }
            setShowErrorMessage(true);
        }
    };

    const closeDialog = () => {
        setValue("");
        setShowErrorMessage(false);
        setShowInvalidInputMessage(false);
        onClose();
    }

    return (
        <Dialog open={open}
            onOpenChange={(event, data) => {
                if (!data.open) {
                    closeDialog();
                }
                setOpen(data.open);
            }}
        >
            <DialogSurface aria-describedby={undefined}>
                <form onSubmit={handleSubmit}>
                    <DialogBody>
                        <DialogTitle>Map toevoegen</DialogTitle>
                        <DialogContent className={styles.content}>
                            <DialogMessage
                                message='de volgende tekens zijn ongeldig: * \ : &gt; &lt; | ? /.'
                                title='Ongeldige invoer:'
                                visible={showInvalidInputMessage}
                                onVisibleChange={setShowInvalidInputMessage}
                            />
                            <DialogMessage
                                message={errorMessage}
                                title='Fout:'
                                visible={showErrorMessage}
                                onVisibleChange={setShowErrorMessage}
                            />
                            <Label required htmlFor={inputId}>
                                Naam
                            </Label>
                            <Input required type='text' value={value} onChange={onChange} id={inputId} autoFocus={true} />
                        </DialogContent>
                        <DialogActions>
                            <DialogTrigger disableButtonEnhancement>
                                <Button appearance="secondary">Sluiten</Button>
                            </DialogTrigger>
                            <Button type="submit" appearance="primary" disabled={!addButtonEnabled}>
                                Toevoegen
                            </Button>
                        </DialogActions>
                    </DialogBody>
                </form>
            </DialogSurface>
        </Dialog>
    );
};

const DialogMessage: React.FC<DialogMessageProps> = ({ title, message, visible, onVisibleChange }) => {

    const [hidden, setHidden] = useState(false);

    useEffect(() => {
        setHidden(!visible)
    }, [visible]);

    useEffect(() => {
        if (hidden) {
            onVisibleChange(false);
        }
    }, [hidden])

    return (
        <div hidden={hidden}>
            <MessageBarGroup>
                <MessageBar hidden={hidden} intent='error' >
                    <MessageBarBody>
                        <MessageBarTitle>{title}</MessageBarTitle>
                        {message}
                    </MessageBarBody>
                    <MessageBarActions
                        containerAction={
                            <Button
                                onClick={() => setHidden(true)}
                                aria-label="dismiss"
                                appearance="transparent"
                                icon={<DismissRegular />}
                            />
                        }
                    />
                </MessageBar>
            </MessageBarGroup>
        </div>
    );
}
