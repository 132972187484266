import {
    useState,
    useContext,
    useCallback,
    useEffect,
    useRef,
    ChangeEvent
} from "react"
import {
    FlatTree,
    TreeItem,
    TreeItemLayout,
    useHeadlessFlatTree_unstable,
    HeadlessFlatTreeItemProps,
    Spinner,
    TreeItemProps,
    TreeItemOpenChangeData,
    TreeItemOpenChangeEvent,
    TreeCheckedChangeData,
    TreeItemValue
} from "@fluentui/react-components";

import {
    useRestoreFocusTarget,
} from "@fluentui/react-components";
import { Icon } from '@fluentui/react/lib/Icon';
import { getFileTypeIconProps, FileIconType } from '@fluentui/react-file-type-icons';
import useWebDavQuery from "../../hooks/useWebDavQuery";
import { FileStat } from 'webdav';
import { WebDAVContext } from "../../context/WebDAVContext";

type FlatItem = HeadlessFlatTreeItemProps & { content: string };

type SubtreeProps = {
    value: FlatItem
    props: TreeItemProps
    updateTreeModel: ((parent: string, children: FlatItem[]) => void)
};

type FolderTreeSelectionProps = {
    onFolderSelected: ((filename: string) => void)
};

export const FolderTreeSelection = ({onFolderSelected}: FolderTreeSelectionProps) => {

    const {client} = useContext(WebDAVContext); 

    const [items, setItems] = useState<FlatItem[]>([]);
    const [checkedItem, setCheckedItem] = useState<TreeItemValue[]>();
    const flatTree = useHeadlessFlatTree_unstable(items);

    const updateTreeModel = (parent: string, children: FlatItem[]) => {
        const index = items.findIndex(item => item.value === parent);
        if (index !== -1) {
            setItems([...items.slice(0, index + 1), ...children, ...items.slice(index + 1)]);
        }
    }

    const handleSelectionChange = useCallback(
        (event: ChangeEvent<HTMLElement>, data: TreeCheckedChangeData) => {
            setCheckedItem([data.value]);
            onFolderSelected(data.value.toString());
        },
        []
    );    
    
    useEffect(() => {
        client.getDirectoryContents("/OS").then(content => {
            const fileStats: FileStat[] = content as FileStat[];
            setItems(fileStats.map(fileStat => ({
                content: fileStat.basename,
                value: fileStat.filename
            })
            ));
        });
    },[]);

      
    return (
        <FlatTree {...flatTree.getTreeProps()} 
            aria-label="Folder Tree Selection" 
            selectionMode="single"
             onCheckedChange={handleSelectionChange}
             checkedItems={checkedItem}
            >
            {Array.from(flatTree.items(), (flatTreeItem) => {
                const { content, ...treeItemProps } = flatTreeItem.getTreeItemProps();
                const subtreeItem: FlatItem = {
                    content,
                    value: flatTreeItem.value,
                    parentValue: flatTreeItem.parentValue
                }
                return (
                    <Subtree props={treeItemProps} value={subtreeItem} updateTreeModel={updateTreeModel} key={flatTreeItem.value} />
                );
            })}
        </FlatTree>
    );
};

const Subtree: React.FC<SubtreeProps> = ({ value: treeItem, props, updateTreeModel }) => {

    const {client} = useContext(WebDAVContext); 

    const focusTargetAttribute = useRestoreFocusTarget();

    const [open, setOpen] = useState(false);
    const { query, items, state } = useWebDavQuery();

    // we need to focus the first item when the subtree is opened
    const firstItemRef = useRef<HTMLDivElement>(null);

    const handleOpenChange = useCallback(
        (e: TreeItemOpenChangeEvent, data: TreeItemOpenChangeData) => {
            setOpen(data.open);
        },
        [setOpen]
    );

    useEffect(() => {

        if (open && state === "idle") {
            query(async () => {
                const fileStats: FileStat[] = await client.getDirectoryContents(treeItem.value.toString()) as FileStat[];
                return fileStats
                    .filter(fileStat => fileStat.type === "directory")
                    .map<FlatItem>((entity: FileStat) => ({
                        content: entity.basename,
                        value: entity.filename,
                        name: entity.filename,
                        parentValue: treeItem.value,
                    }));
            });
        }
    }, [open, state, items]);

    useEffect(() => {
        if (open && state === "loaded") {
            updateTreeModel(treeItem.value.toString(), items);
            firstItemRef.current?.focus();
        }
    }, [state]);

    return (
        <>
            <TreeItem
                aria-description={treeItem.content}
                {...focusTargetAttribute}
                {...props}
                itemType={open && state === "loaded" && items?.length === 0 ? "leaf" : "branch"}
                onOpenChange={handleOpenChange}
            >
                <TreeItemLayout
                    expandIcon={state === "loading" ? <Spinner size="tiny" /> : undefined}
                    iconBefore={<Icon {...getFileTypeIconProps({ type: FileIconType.folder, size: 20 })} />}>
                    {treeItem.content}
                </TreeItemLayout>
            </TreeItem>
        </>
    );
};