import { useContext } from "react";
import { Folder, FolderContextType } from "../types/Folder";
import * as ACData from "adaptivecards-templating";
import {
    dialog,
    tasks,
    AdaptiveCardDialogInfo,
    DialogDimension,
} from "@microsoft/teams-js";
import { FileStat } from 'webdav';
import { FolderContext } from "../context/FolderContextProvider";

type PropertiesReturn = {
    openFolderPropertiesDialog: (folder: Folder, submitHandler: dialog.DialogSubmitHandler) => void;
    openDocumentPropertiesDialog: (document: FileStat, submitHandler: dialog.DialogSubmitHandler) => void;
    openEditDocumentDialog: (document: FileStat, onSubmit: (result: dialog.ISdkResponse) => void) => void;
}

const templatePayload = {
    "type": "AdaptiveCard",
    "$schema": "http://adaptivecards.io/schemas/adaptive-card.json",
    "version": "1.5",
    "body": [
        {
            "type": "TextBlock",
            "text": "${displayname}",
            "wrap": true,
            "size": "extraLarge",
            "style": "heading"
        },
        {
            "type": "TextBlock",
            "text": "Algemene eigenschappen",
            "wrap": true,
            "size": "large",
        },
        {
            "type": "FactSet",
            "spacing": "large",
            "facts": [
                {
                    "title": "Naam",
                    "value": "${displayname}"
                },
                {
                    "title": "Aangemaakt op",
                    "value": "{{DATE(${creationdate})}}"
                },
                {
                    "title": "Gewijzigd op",
                    "value": "${getlastmodified}"
                }

            ]
        },
        {
            "type": "TextBlock",
            "text": "Specifieke eigenschappen",
            "wrap": true,
            "size": "large",
        },
        {
            "type": "FactSet",
            "spacing": "large",
            "facts": [
                {
                    "title": "Test Name Property",
                    "value": "${TestNameProperty}"
                },
                {
                    "title": "Test String Property",
                    "value": "${TestStringProperty1}"
                },
                {
                    "title": "Test Date Property",
                    "value": "${TestDateProperty1}"
                }

            ]
        },
        {
            "type": "ActionSet",
            "actions": [
                {
                    "type": "Action.Submit",
                    "title": "Sluiten",
                    "id": "propertiesSubmit",
                    "tooltip": "Sluit het eigenschappen venster"
                }
            ]
        }
    ]
};

const documentPayload = {
    "type": "AdaptiveCard",
    "$schema": "http://adaptivecards.io/schemas/adaptive-card.json",
    "version": "1.2",
    "body": [
        {
            "type": "ActionSet",
            "actions": [
                {
                    "type": "Action.OpenUrl",
                    "title": "Action.OpenUrl",
                    "url": "${url}"
                }
            ]
        }
    ]
}

const usePropertiesCard = (): PropertiesReturn => {

    const { fetchProperties } = useContext(FolderContext) as FolderContextType;

    const openFolderPropertiesDialog = (folder: Folder, submitHandler: dialog.DialogSubmitHandler) => {

        fetchProperties(folder.path).then(properties => {

            console.log(properties);

            var template = new ACData.Template(templatePayload);

            var card = template.expand({ $root: { ...properties.data.props } });

            let cardInfo: AdaptiveCardDialogInfo = {
                card: JSON.stringify(card),
                size: {
                    height: DialogDimension.Medium,
                    width: DialogDimension.Medium
                },
                title: "Mapeigenschappen",
            }

            dialog.adaptiveCard.open(cardInfo, submitHandler);
        })
    }

    const openDocumentPropertiesDialog = (document: FileStat, submitHandler: dialog.DialogSubmitHandler) => {

        fetchProperties(document.filename).then(properties => {

            console.log(properties);

            var template = new ACData.Template(templatePayload);

            var card = template.expand({ $root: { ...properties.data.props } });

            let cardInfo: AdaptiveCardDialogInfo = {
                card: JSON.stringify(card),
                size: {
                    height: DialogDimension.Medium,
                    width: DialogDimension.Medium
                },
                title: "Documenteigenschappen",
            }

            dialog.adaptiveCard.open(cardInfo, submitHandler);
        })
    }    
    const openEditDocumentDialog = (document: FileStat, onSubmit: (result: dialog.ISdkResponse) => void) => {

        const context = {
            subEntityId: document.filename
        };

        const encodedContext: string = encodeURIComponent(JSON.stringify(context));
        const baseUrl = window.location.origin + (process.env.REACT_APP_ROUTER_BASE || "");

        dialog.url.open({
            url: `${baseUrl}/index.html#/edit?context=${encodedContext}`,
            title: "Document online bewerken",
            size: {
                height: DialogDimension.Small,
                width: DialogDimension.Medium
            }
        }, onSubmit
        );
    }

    return { openFolderPropertiesDialog, openDocumentPropertiesDialog, openEditDocumentDialog };
};

export default usePropertiesCard;