import { useState, useEffect, useContext } from "react";
import {
    app,
    pages,
} from "@microsoft/teams-js";
import { getFileTypeIconProps, FileIconType } from '@fluentui/react-file-type-icons';
import { FolderTreeSelection } from "./browser/FolderTreeSelection";

import {
    makeStyles,
    shorthands,
    tokens,
    OverlayDrawer,
    DrawerBody,
    DrawerHeader,
    DrawerHeaderTitle,
    Button,
    CardHeader,
    Card,
    Caption1,
    Text,
    Subtitle2Stronger,
    CardFooter
} from "@fluentui/react-components";

import {
    Dismiss24Regular,
    CheckmarkSquare24Regular,
    Folder24Regular
} from "@fluentui/react-icons";

import { Icon } from '@fluentui/react/lib/Icon';
import { WebDAVContext } from "../context/WebDAVContext";
import { ErrorBoundary } from "react-error-boundary";
import { BrowserErrorMessage } from "./BrowserErrorMessage";

const useStyles = makeStyles({

    main: {
        backgroundColor: tokens.colorNeutralBackground1,
        width: "100%",
        align: "center",
    },
    card: {
        ...shorthands.margin("auto"),
        width: "80%",
        height: "fit-content",
        ...shorthands.padding("1em")
    },
    button: {
        ...shorthands.margin("5px")
    }
});

const getDisplayName = (filename: string | undefined) => {
    return filename ? filename.substring(filename.lastIndexOf("/") + 1) : "Geen selectie";
};

export default function ChannelBrowserConfiguration() {

    const styles = useStyles();
    const [isOpen, setIsOpen] = useState(false);
    const [selection, setSelection] = useState<string | undefined>();
    const [channelName, setChannelName] = useState("");
    const [selectedFolder, setSelectedFolder] = useState<string | undefined>();

    useEffect(() => {
        pages.config.setValidityState(!isOpen && selection !== null);
    }, [isOpen, selection]);

    useEffect(() => {

        pages.config.setValidityState(!!!selection);

        app.initialize().then(async () => {
            const context = await app.getContext();
            setChannelName(context.channel?.displayName || "Geen kanaal");
            const config = await pages.getConfig();
            if (config.entityId) {
                setSelection(config.entityId);
            }
        });
    }, []);

    const onFolderSelected = (filename: string) => {
        setSelectedFolder(filename);
    };

    const openFolderSelection = () => {
        setSelectedFolder(undefined);
        setIsOpen(true);
    };

    const saveSelectedFolder = () => {
        setIsOpen(false);
        setSelection(selectedFolder);

        if (selectedFolder) {
            pages.config.registerOnSaveHandler((saveEvent) => {

                const baseUrl = window.location.origin + (process.env.REACT_APP_ROUTER_BASE || "");

                const configPromise = pages.config.setConfig({
                    websiteUrl: baseUrl + "/index.html#/channeltab",
                    contentUrl: baseUrl + "/index.html#/channeltab",
                    entityId: selectedFolder,
                    suggestedDisplayName: getDisplayName(selectedFolder)
                });
                configPromise
                    .then((result) => { saveEvent.notifySuccess() })
                    .catch((error) => { saveEvent.notifyFailure("failure message") });
            })
        }
    };

    const { initializeClient } = useContext(WebDAVContext);

    return (
        <ErrorBoundary FallbackComponent={BrowserErrorMessage}
            onReset={initializeClient}
        >
            <div className={styles.main}>
                <OverlayDrawer
                    open={isOpen}
                    onOpenChange={(_, { open }) => setIsOpen(open)}
                    position="end"
                    size="medium"
                >
                    <DrawerHeader>
                        <DrawerHeaderTitle
                            heading={<div>Selecteer een folder</div>}
                            action={
                                <>
                                    <Button
                                        className={styles.button}
                                        aria-label="Close"
                                        icon={<Dismiss24Regular />}
                                        onClick={() => setIsOpen(false)}
                                    >Annuleren</Button>
                                    <Button
                                        className={styles.button}
                                        aria-label="Selecteer"
                                        icon={<CheckmarkSquare24Regular />}
                                        disabled={!selectedFolder}
                                        onClick={saveSelectedFolder}
                                        appearance="primary"
                                    >Selecteer</Button>
                                </>
                            }
                        >
                        </DrawerHeaderTitle>
                    </DrawerHeader>

                    <DrawerBody>
                        <FolderTreeSelection onFolderSelected={onFolderSelected} />
                    </DrawerBody>
                </OverlayDrawer>
                <Subtitle2Stronger>Gekoppelde FileNet folder</Subtitle2Stronger>
                <p />
                <Text>Dit is de FileNet folder die aan het kanaal <b>"{channelName}"</b> is gekoppeld. De inhoud van deze folder is alleen zichtbaar voor de personen met voldoende rechten.</Text>
                <p />
                <Card className={styles.card} size="large" role="article" appearance="filled-alternative">
                    <CardHeader
                        image={<Icon {...getFileTypeIconProps({ type: FileIconType.folder, size: 32 })} />}
                        header={<Text weight="semibold">{getDisplayName(selection)}</Text>}
                        description={<Caption1>{selection ? selection.substring(0, selection.lastIndexOf("/")) : "Geen selectie"}</Caption1>}
                    />
                    <CardFooter
                        action={<Button
                            appearance="secondary"
                            onClick={openFolderSelection}
                            icon={<Folder24Regular />}>
                            Selecteer Folder
                        </Button>}
                    ></CardFooter>
                </Card>
            </div>
        </ErrorBoundary>
    );
}