import { useContext, useEffect, useState } from "react";
import { TeamsFxContext } from "./Context";
import FoldersBreadcrumb from './browser/FoldersBreadcrumb';
import FolderContent from "./browser/FolderContentView";
import FolderToolbar from "./browser/FolderToolbar";
import {
  app,
  pages
} from "@microsoft/teams-js";

import {
  Divider,
  makeStyles,
  tokens,
  shorthands,
  Dropdown,
  Option
} from "@fluentui/react-components";

import { checkInTeams } from "../utils/checkInTeams";
import FolderContextProvider, { FolderContext } from "../context/FolderContextProvider";
import { FolderContextType } from "../types/Folder";
import { ErrorBoundary } from "react-error-boundary";
import { BrowserErrorMessage } from "./BrowserErrorMessage";
import { WebDAVContext } from "../context/WebDAVContext";
import { DocumentViewer } from "./viewer/DocumentViewer";
import { DocumentViewer2 } from "./viewer/DocumentViewer2";

const useStyles = makeStyles({
  root: {
    backgroundColor: tokens.colorNeutralBackground1,
  },
  container: {
    ...shorthands.padding("2em"),
    width: "100%"
  }
});

type FolderPaneProps = {
  rootPath: string | null
  initialPath: string | null
  storageKey: string
};

/**
 * Return the key to use for the local storage of the state.
 * 
 * @param context the context
 * @param entityId the entity id
 * @returns the local storage key
 */
const getStorageKey = (context: app.Context, entityId: string) => {

  if (context.channel) {
    return `${context.channel.id}_${entityId}`;
  } else if (context.chat) {
    return `${context.chat.id}_${entityId}`;
  } else if (context.meeting) {
    return `${context.meeting.id}_${entityId}`;
  }
  return entityId;
}

export default function BrowserTab() {

  const styles = useStyles();
  const [rootPath, setRootPath] = useState(null);
  const [initialPath, setInitialPath] = useState<string | null>(null);
  const [storageKey, setStorageKey] = useState<string>("");

  const { initializeClient } = useContext(WebDAVContext);

  useEffect(() => {
    if (checkInTeams()) {
      app.initialize().then(async () => {
        const context = await app.getContext();

        /**
         * The root of the pages is initialized from the configuration
         */
        const pageConfig = await pages.getConfig();
        if (pageConfig.entityId) {
          setRootPath(pageConfig.entityId);
        }
        /**
         * Initialize the page from the context
         */
        const pathFromContext = context.page.subPageId;

        if (pathFromContext) {
          setInitialPath(pathFromContext);
        }

        setStorageKey(getStorageKey(context, pageConfig.entityId));
      })
    }
  }, []);

  return (
    <div className={styles.root}>
      <ErrorBoundary FallbackComponent={BrowserErrorMessage}
        onReset={initializeClient}
      >
        <FolderContextProvider>
          <FolderPane rootPath={rootPath} initialPath={initialPath} storageKey={storageKey} />
        </FolderContextProvider>
      </ErrorBoundary>
    </div>
  );
}

// 
export function FolderPane(props: FolderPaneProps) {

  const { themeString } = useContext(TeamsFxContext);
  const { folder, viewerDocument, setFolderPath } = useContext(FolderContext) as FolderContextType;

  const styles = useStyles();

  const { rootPath, initialPath, storageKey } = props;
  /**
   * Viewer stuff
   */
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [viewer, setViewer] = useState("Viewer 1");

  /*
   * If the initial path is set, we use that value. Otherwise the stored state
   * coupled to the storage key is used. If there is no state yet, then the rootPath
   * is used.
   */
  useEffect(() => {
    if (initialPath) {
      setFolderPath(initialPath);
    } else if (rootPath) {
      const storedPath = localStorage.getItem(storageKey);
      if (storedPath) {
        setFolderPath(storedPath);
      } else {
        setFolderPath(rootPath);
      }
    }
  }, [rootPath, initialPath, storageKey]);

  /*
   * Keep track of the current path of this browser page and store the state 
   * in local storage.
   */
  useEffect(() => {
    if (rootPath) {
      localStorage.setItem(storageKey, folder.path);
    }
  }, [folder]);

  /**
   * Open document in viewer if viewer document is set by the FolderContext 
   * provider.
   */
  useEffect(() => {
    if (viewerDocument) {
      setIsViewerOpen(true);
    }
  }, [viewerDocument]);

  return (
    (!rootPath ? (
      <div>Loading...</div>
    ) : (
      <>
        <FolderToolbar />
        <Divider appearance="strong" />
        <div className={`${themeString === "default" ? "light" : themeString === "dark" ? "dark" : "contrast"}`}>
          <div className={styles.container}>
            <FoldersBreadcrumb rootPath={rootPath} />
            <FolderContent />
            <Dropdown
              defaultSelectedOptions={["Viewer 1"]}
              onOptionSelect={(_, data) => setViewer(data.optionText)}
              placeholder="Select an viewer"
            >
              <Option value="Viewer 1">apryse.com</Option>
              <Option value="Viewer 2">pspdfkit.com</Option>
            </Dropdown>
            {(viewer === "Viewer 1") ? (
              <DocumentViewer isOpen={isViewerOpen} closeViewer={() => setIsViewerOpen(false)} file={viewerDocument} />) : (
              <DocumentViewer2 isOpen={isViewerOpen} closeViewer={() => setIsViewerOpen(false)} file={viewerDocument} />)
            }

          </div>
        </div>
      </>
    )
    )
  )
}