import React, {
    useState,
    PropsWithChildren,
    createContext,
    useEffect,
    useContext
} from 'react';
import {
    useErrorBoundary
} from "react-error-boundary";

import {
    Folder,
    FolderContextType,
    DocumentAction,
    EditDocumentProps,
    EditDocumentInfo,
    SaveDocumentInfo,
    AddDocumentProps,
    AddDocumentResponse,
    AddFolderResponse
} from "../types/Folder"
import { FileStat, Response, ResponseDataDetailed } from 'webdav';
import { WebDAVContext } from './WebDAVContext';
import { RemoteFile } from '../types/RemoteFile';
import { tasks } from "@microsoft/teams-js";

const editingPath = "../teams-zfc/edit";

export const FolderContext = createContext<FolderContextType | null>(null);

const FolderContextProvider: React.FC<PropsWithChildren> = ({ children }) => {

    const { showBoundary } = useErrorBoundary();

    const { client } = useContext(WebDAVContext);

    const [folder, setFolder] = useState<Folder>({
        id: "root",
        path: "/",
        title: "Home",
        content: [],
        loading: false
    });

    const [viewerDocument, setViewerDocument] = useState<RemoteFile | null>(null);

    const setFolderPath = (path: string) => {
        const rootFolder: Folder = {
            id: path,
            path,
            title: path.substring(path.lastIndexOf("/") + 1),
            content: [],
            loading: true
        }
        setFolder(() => rootFolder);
    };

    useEffect(() => {
        if (folder.loading) {
            fetchFolder();
        }
    }, [folder]);

    const fetchFolder = () => {

        client.getDirectoryContents(folder.path).then(content => {
            const myArray: FileStat[] = content as FileStat[];
            console.log(myArray);
            folder.content = myArray;
            setFolder({ ...folder, content: myArray, loading: false });
        }).catch(reason => {
            console.log("Error fetching directory content: ", reason);
            showBoundary(reason);
        });
    }

    const updateFolder = (newFolder: FileStat) => {
        setFolder(() => {
            const item: Folder = {
                id: newFolder.filename,
                path: newFolder.filename,
                content: [],
                title: newFolder.basename,
                loading: true
            };
            return item;
        });
    }

    const fetchProperties = async (path: string) => {
        const properties = await client.stat(path, {
            details: true
        }) as ResponseDataDetailed<FileStat>;
        return properties;
    }

    const executeDocumentAction = (document: FileStat | string, action: DocumentAction) => {
        switch (action) {
            case "download":
                downloadDocument(document as FileStat);
                break;
            case "view":
                viewDocument(document as FileStat);
                break;
        }
    }

    const viewDocument = async (inputDocument: FileStat) => {

        const data = await client.getFileContents(inputDocument.filename) as ArrayBuffer;
        const file = new Blob([data], { type: 'application/octet-stream' });

        setViewerDocument({
            content: file,
            file: inputDocument,
            loading: false,
            extension: inputDocument.basename.substring(inputDocument.basename.lastIndexOf(".") + 1)
        })
    }

    const downloadDocument = async (inputDocument: FileStat) => {

        const data = await client.getFileContents(inputDocument.filename) as ArrayBuffer;
        const file = new Blob([data], { type: 'application/octet-stream' });

        const element = document.createElement("a");
        element.href = URL.createObjectURL(file);
        element.download = inputDocument.basename;
        document.body.appendChild(element);
        element.click();
        element.remove();
    }

    /**
     * Calls the office editor service to fetch the editing state of the file.
     * 
     * @param filename the filename
     * @returns an object containing the editing information
     */
    const getLockInfo = async (filename: string, userId: string): Promise<EditDocumentInfo> => {

        const requestUrl = `${editingPath}/getEditingInfo`;

        const customResponse: Response = await client.customRequest(requestUrl, {
            data: JSON.stringify({ filename, userId }),
            method: "POST"
        });

        const editDocumentInfo = await customResponse.text();
        console.log("Edit document info: ", editDocumentInfo);
        return JSON.parse(editDocumentInfo);
    }

    /**
     * Calls the office editor service to start an editing session.
     * 
     * @param props an object containing the editing parameters 
     * @returns an object containing the editing information
     */
    const editDocument = async (props: EditDocumentProps): Promise<EditDocumentInfo> => {

        const requestUrl = `${editingPath}/startEditing`;
        const customResponse: Response = await client.customRequest(requestUrl, {
            data: JSON.stringify(props),
            method: "POST"
        });

        return JSON.parse(await customResponse.text());
    }

    /**
     * Calls the office editor service to end the editing session.
     * 
     * @param props an object containing the editing parameters
     * @returns an object containing the result of the saving operation
     */
    const saveDocument = async (props: EditDocumentProps): Promise<SaveDocumentInfo> => {
        const requestUrl = `${editingPath}/endEditing`;
        const customResponse: Response = await client.customRequest(requestUrl, {
            data: JSON.stringify(props),
            method: "POST"
        });

        return JSON.parse(await customResponse.text());
    }

    /**
     * Adds a document to the repository. Existing files will not be overwritten.
     * After the file is uploaded first a lock is called to fetch the lock token.
     * Next an unlock is called using the lock token to force a check-in.
     * 
     * @param documentProps the document to add
     * @returns the result of the action
     */
    const addDocument = async ({ filename, file }: AddDocumentProps): Promise<AddDocumentResponse> => {

        const exists = await client.exists(filename);
        if (exists) {
            return { file, response: 'exists' }
        }

        const content = await file.arrayBuffer();
        try {
            const result = await client.putFileContents(filename, content, { overwrite: false })

            if (result) {
                const lock = await client.lock(filename);
                await client.unlock(filename, lock.token);
                return { file, response: 'ok' }
            } else {
                return { file, response: 'error', message: "Het toevoegen is niet gelukt" }
            }
        } catch (error) {
            if (error?.status === 401) {
                return { file, response: 'error', message: "Documenten toevoegen is niet toegestaan in deze map" }
            } else {
                return { file, response: 'error', message: error?.message || "Onbekende fout" }
            }
        }
    }

    /**
     * Creates a new folder if the folder does not yet exists.
     * 
     * @param parentPath the parent path
     * @param folderName the folder name
     * @returns false, if the action was not successful or the folder already exists
     */
    const createFolder = async (parentPath: string, folderName: string): Promise<AddFolderResponse> => {

        try {
            const path = `${parentPath}/${folderName}`;
            const exists = await client.exists(path);
            if (!exists) {
                await client.createDirectory(path);
                return { response: "ok" }
            } else {
                return { response: "exists" };
            }
        } catch (error) {
            if (error?.status === 401) {
                return { response: 'error', message: "Mappen toevoegen is niet toegestaan in deze map" }
            } else if (error?.status === 409) {
                return { response: 'error', message: "De bovenliggende map bestaat niet" }
            } else {
                return { response: 'error', message: error?.message || "Onbekende fout" }
            }
        }
    }

    return (
        <FolderContext.Provider value={{
            folder,
            viewerDocument,
            editDocument,
            saveDocument,
            addDocument,
            setFolderPath,
            updateFolder,
            getLockInfo,
            createFolder,
            executeDocumentAction,
            fetchProperties
        }}>
            {children}
        </FolderContext.Provider>
    );
};

export default FolderContextProvider;