import { useEffect, useState } from "react";
import {
    app,
    pages,
    ConversationResponse,
    conversations,
    OpenConversationRequest
} from "@microsoft/teams-js";

export type ChatState = "not_supported" | "initializing" | "none" | "open" | "available" | "mystery";

export const useChat = () => {

    const [conversationStateKey, setConversationStateKey] = useState("");
    const [chatState, setChatState] = useState<ChatState>("none");
    const [chatContext, setChatContext] = useState<null | string>(null);

    useEffect(() => {
        if (!conversations.isSupported() ) {
            setChatState("not_supported");
        } else {
            app.getContext().then(async (context) => {
                setChatState(!!context.channel ? "none" : "not_supported");
            })
        }
    }, []);

    useEffect(() => {
        if (chatContext) {
            app.getContext().then(async (context) => {
                if (context.channel) {
                    setConversationStateKey(`${context.channel.id}_${chatContext}_chat`);
                    conversations.closeConversation();
                }
            });
        }
    }, [chatContext]);

    useEffect(() => {
        setChatState(localStorage.getItem(conversationStateKey) ? "available" : "none");
    }, [conversationStateKey]);

    const openConversation = async (title?: string) => {
        const appContext = await app.getContext();
        const pageConfig = await pages.getConfig();

        const conversationRequest: OpenConversationRequest = {
            subEntityId: chatContext,
            entityId: pageConfig.entityId,
            channelId: appContext.channel.id,
            title,
            onStartConversation,
            onCloseConversation,
        }

        const conversationId = localStorage.getItem(conversationStateKey);
        if (conversationId) {
            conversationRequest.conversationId = conversationId;
            setChatState("open");
        } else {
            setChatState("initializing");
        }
        conversations.openConversation(conversationRequest);
    }

    const onStartConversation = (conversationResponse: ConversationResponse) => {
        localStorage.setItem(conversationStateKey, conversationResponse.conversationId);
        setChatState("open");
    }
    const updateChatState = () => {
        return prevState => {
            if (prevState === "initializing") {
                return "none";
            } else if (prevState === "open") {
                return "available";
            } else {
                return "mystery";
            }
        };
    }

    const onCloseConversation = (conversationResponse: ConversationResponse) => {
        setChatState(updateChatState());
    }

    const closeConversation = () => {
        conversations.closeConversation();
        setChatState(updateChatState());
    }

    return { setChatContext, chatState, onOpenConversation: openConversation, closeConversation };
}