import { useEffect, useContext, useState } from "react";

import {
    Text,
    DrawerBody,
    DrawerHeader,
    DrawerHeaderTitle,
    DrawerHeaderNavigation,
    Caption1,
    InlineDrawer,
    Toolbar,
    ToolbarButton,
    Card,
    CardHeader,
    makeStyles,
    tokens,
    shorthands,
    mergeClasses,
} from "@fluentui/react-components";

import {
    FolderPeople24Regular,
    ArrowLeft24Regular,
    ArrowRight24Regular,
} from "@fluentui/react-icons";

import { FileStat, ResponseDataDetailed } from "webdav";
import { WebDAVContext } from "../../context/WebDAVContext";

const useStyles = makeStyles({
    drawer: {
        display: "flex",
        flexDirection: "column",
    },
    drawerBody: {
        backgroundColor: tokens.colorNeutralBackground2,
    },
    collapsedDrawer: {
        backgroundColor: tokens.colorNeutralBackground2,
        width: "60px"
    },
    drawerCardsGrid: {
        ...shorthands.gap("16px"),
        display: "flex",
        flexDirection: "column",
    },
    card: {
        ...shorthands.padding("6px", "6px"),
        height: "fit-content",
    },
    caption: {
        color: tokens.colorNeutralForeground3,
    },
});

type RootFolderProps = {
    rootFolder: string,
    onRootFolderSelected: ((filename: string) => void)
};

type RootFolderCardProps = {
    folder: FileStat,
    selected: boolean,
    onSelectionChange: (path: string) => void;
}

// TODO: make this configurable
const rootPath = "/OS";
const browserDrawerExpandedKey = "bd_expanded";

const emptyResponse = {
    data: [],
    headers: {},
    status: 0,
    statusText: ''
}

/**
 * Returns the initial expanded state of the drawer. If no data is stored 
 * yet then the drawer is shown expanded.
 * 
 * @returns the initial state
 */
const getInitialDrawerState = () => {
    const storedExpandedState = localStorage.getItem(browserDrawerExpandedKey);
    if (!storedExpandedState) {
        return true;
    }
    return JSON.parse(localStorage.getItem(browserDrawerExpandedKey)) as boolean;
}

const BrowserDrawer = ({ rootFolder: newSelectedRootFolder, onRootFolderSelected }: RootFolderProps) => {

    const styles = useStyles();
    const [expanded, setExpanded] = useState(getInitialDrawerState());

    const {client} = useContext(WebDAVContext);

    const [rootFolders, setRootFolders] = useState<ResponseDataDetailed<Array<FileStat>>>(emptyResponse);
    const [selectedRootFolder, setSelectedRootFolder] = useState("");

    /**
     * Do the initial fetch of the root folders. Perhaps store this 
     * later in local storage as it will not likely change?
     */
    useEffect(() => {
        client.getDirectoryContents(rootPath, {
            details: true
        }).then(content => {
            setRootFolders(content as ResponseDataDetailed<Array<FileStat>>);
        });
    }, []);

    /**
     * A new root folder is selected by the parent component. This is the case
     * when the page is opened from a link.
     */
    useEffect(() => {
        setSelectedRootFolder(newSelectedRootFolder);
    }, [newSelectedRootFolder]);

    /**
     * Update the state and notify the parent component that a new root folder 
     * is selected.
     * 
     * @param path the selected path
     */
    const onSelectionChange = (path: string) => {
        setSelectedRootFolder(path);
        onRootFolderSelected(path);
    }

    /**
     * Save the browser expanded state in the local storage.
     */
    useEffect( () => {
        localStorage.setItem(browserDrawerExpandedKey, JSON.stringify(expanded));
    }, [expanded]);

    return (
        (expanded ? (
            <InlineDrawer separator open size="small" className={styles.drawerBody}>
                <DrawerHeader className={styles.drawerBody}>
                    <DrawerHeaderNavigation>
                        <Toolbar>
                            <ToolbarButton
                                aria-label="Back"
                                appearance="subtle"
                                onClick={() => setExpanded(false)}
                                icon={<ArrowLeft24Regular />}
                            />
                        </Toolbar>
                    </DrawerHeaderNavigation>
                    <DrawerHeaderTitle>Mijn Persoonlijke Omgeving</DrawerHeaderTitle>
                </DrawerHeader>
                <DrawerBody className={mergeClasses(styles.drawer, styles.drawerBody)}> {
                    (rootFolders.data.length === 0 ? (
                        <div>Persoonlijke teams omgeving aan het laden...</div>
                    ) : (
                        <>
                            <Text>Dit is de persoonlijke teams omgeving van de FileNet applicatie.<p/></Text>
                            <div className={styles.drawerCardsGrid} role="list">
                                {rootFolders.data.map(rootFolder =>
                                    <RootFolderCard
                                        folder={rootFolder}
                                        selected={rootFolder.filename === selectedRootFolder}
                                        onSelectionChange={onSelectionChange}
                                        key={rootFolder.filename}
                                    />
                                )}
                            </div>
                        </>)
                    )
                }</DrawerBody>
            </InlineDrawer>
        ) : (
            <InlineDrawer separator open size="small" className={styles.collapsedDrawer}>
                <DrawerHeader>
                    <DrawerHeaderNavigation>
                        <Toolbar>
                            <ToolbarButton
                                aria-label="Back"
                                appearance="subtle"
                                onClick={() => setExpanded(true)}
                                icon={<ArrowRight24Regular />}
                            />
                        </Toolbar>
                    </DrawerHeaderNavigation>
                </DrawerHeader>
            </InlineDrawer>
        ))
    );
}

const RootFolderCard: React.FC<RootFolderCardProps> = ({ folder, selected, onSelectionChange }) => {

    const styles = useStyles();

    return (
        <Card orientation="horizontal"
            appearance="subtle"
            selected={selected}
            className={styles.card}
            onSelectionChange={() => onSelectionChange(folder.filename)}
            size="medium">
            <CardHeader
                header={<Text weight="semibold">{folder.basename}</Text>}
                description={
                    <Caption1 className={styles.caption}>{folder.props?.description as string}</Caption1>
                }
                image={<FolderPeople24Regular />}
            />
        </Card>
    )
}

export default BrowserDrawer;