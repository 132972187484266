import { useContext } from "react";
import {
    CardUi24Regular,
    ClipboardLink24Regular,
    DocumentAdd24Regular,
    FolderAdd24Regular,
    Delete24Regular,
    Add24Regular,
    ArrowExitRegular,
    ShareRegular,
} from "@fluentui/react-icons";
import {
    Toolbar,
    ToolbarButton,
    Tooltip,
    Menu,
    MenuTrigger,
    MenuPopover,
    MenuItem,
    MenuList,
    makeStyles,
    ToolbarGroup,
} from "@fluentui/react-components";

import usePropertiesCard from "../../hooks/usePropertiesCard";
import { FolderContext } from "../../context/FolderContextProvider";
import { FolderContextType } from "../../types/Folder";
import useCopyLinkDialog from "../../hooks/useCopyLinkDialog";
import useAddDocumentsDialog from "../../hooks/useAddDocumentsDialog";
import useAddFolderDialog from "../../hooks/useAddFolderDialog";

import {
    dialog,
    sharing,
} from "@microsoft/teams-js";
import { WebDAVContext } from "../../context/WebDAVContext";
import CopyLinkDialog from '../dialogs/CopyLinkDialog';
import AddDocumentsDialog from '../dialogs/AddDocumentsDialog';
import AddFolderDialog from '../dialogs/AddFolderDialog';
import useDeepLinks from "../../hooks/useDeepLink";
import ChatToolbar from "../toolbar/ChatToolbar";

const useStyles = makeStyles({
    toolbar: {
        justifyContent: "space-between",
    },
});

export default function FolderToolbar() {

    const farGroupStyles = useStyles();

    const { openFolderPropertiesDialog } = usePropertiesCard();
    const { folder, setFolderPath } = useContext(FolderContext) as FolderContextType;
    
    const onPropertiesSubmit: dialog.DialogSubmitHandler = (result) => {
        console.log("The result is: ", result?.result);
    };

    const { isOpen: isCopyLinkDialogOpen, openCopyLinkDialog, closeCopyLinkDialog, deepLink } = useCopyLinkDialog();
    const { resetClient } = useContext(WebDAVContext);
    const { isOpen: isAddDocumentsDialogOpen, openAddDocumentsDialog, closeAddDocumentsDialog } = useAddDocumentsDialog();
    const { isOpen: isAddFolderDialogOpen, openAddFolderDialog, closeAddFolderDialog } = useAddFolderDialog();

    const onFilesAdded = () => {
        setFolderPath(folder.path);
    };

    const { getDeepLink } = useDeepLinks();

    /**
     * Opens the standard share dialog of teams using the deep link from the 
     * current context.
     */
    const shareToTeams = async () => {

        const deepLink = await getDeepLink(folder.path, folder.title);
        sharing.shareWebContent({
            content: [
                {
                    type: "URL",
                    url: deepLink,
                    message: `Dit is een link naar "${folder.title}"`,
                    preview: true
                }]
        })
    }

    /**
     * Opens the copy link dialog using the deep link from the current context.
     */
    const onOpenCopyLinkDialog = async () => {
        const deepLink = await getDeepLink(folder.path, folder.title);
        openCopyLinkDialog(deepLink);
    }

    return (
        <>
            <Toolbar aria-label="Default" size="medium" className={farGroupStyles.toolbar}>
                <ToolbarGroup role="presentation">
                    <Tooltip content="Eigenschappen" relationship="label">
                        <ToolbarButton
                            aria-label="Eigenschappen"
                            icon={<CardUi24Regular />}
                            onClick={() => openFolderPropertiesDialog(folder, onPropertiesSubmit)}
                        >Eigenschappen</ToolbarButton>
                    </Tooltip>

                    <Menu hasIcons>
                        <MenuTrigger>
                            <Tooltip content="Toevoegen aan deze map" relationship="label">
                                <ToolbarButton
                                    aria-label="Toevoegen"
                                    icon={<Add24Regular />}
                                >Toevoegen</ToolbarButton>
                            </Tooltip>
                        </MenuTrigger>
                        <MenuPopover>
                            <MenuList>
                                <MenuItem
                                    icon={<FolderAdd24Regular />}
                                    onClick={() => openAddFolderDialog()}
                                >Map toevoegen</MenuItem>
                                <MenuItem
                                    icon={<DocumentAdd24Regular />}
                                    onClick={() => openAddDocumentsDialog()}
                                >Documenten toevoegen</MenuItem>
                            </MenuList>
                        </MenuPopover>
                    </Menu>

                    <Tooltip content="Deze map verwijderen" relationship="label">
                        <ToolbarButton
                            aria-label="Map verwijderen"
                            icon={<Delete24Regular />}
                            onClick={() => openFolderPropertiesDialog(folder, onPropertiesSubmit)}
                        >Verwijderen</ToolbarButton></Tooltip>

                    <Tooltip content="Link naar deze map kopiëren" relationship="label">
                        <ToolbarButton
                            aria-label="Link kopiëren"
                            icon={<ClipboardLink24Regular />}
                            onClick={onOpenCopyLinkDialog}
                        >Link kopiëren</ToolbarButton></Tooltip>

                    {sharing.isSupported() ?
                        <Tooltip content="Link delen in teams" relationship="label">
                            <ToolbarButton
                                aria-label="Delen"
                                icon={<ShareRegular />}
                                onClick={shareToTeams}
                            >Delen</ToolbarButton></Tooltip> : null
                    }

                    <Tooltip content="Afmelden" relationship="label">
                        <ToolbarButton
                            aria-label="Afmelden"
                            icon={<ArrowExitRegular />}
                            onClick={resetClient}
                        >Afmelden</ToolbarButton></Tooltip>
                </ToolbarGroup>
                <ChatToolbar/>
            </Toolbar >
            <CopyLinkDialog isOpen={isCopyLinkDialogOpen} onClose={closeCopyLinkDialog} label={folder.title} deepLink={deepLink}></CopyLinkDialog>
            <AddDocumentsDialog isOpen={isAddDocumentsDialogOpen} onClose={closeAddDocumentsDialog} onFilesAdded={onFilesAdded} />
            <AddFolderDialog isOpen={isAddFolderDialogOpen} onClose={closeAddFolderDialog} />

        </>
    )
};