import { useContext, useEffect, useState } from "react";

import {
    ToolbarButton,
    ToolbarGroup,
    MenuTrigger,
    Menu,
    MenuPopover,
    MenuList,
    MenuItem,
    Tooltip,
} from "@fluentui/react-components";

import {
    Chat24Regular,
    ChatAdd24Regular,
    ChatArrowBackRegular,
    ChatDismiss24Regular,
    ChatWarning24Regular
} from "@fluentui/react-icons";

import { FolderContext } from "../../context/FolderContextProvider";
import { FolderContextType } from "../../types/Folder";
import { useChat } from "../../hooks/useChat";
import NewChatDialog from "../dialogs/NewChatDialog";

export default function ChatToolbar() {

    const { folder } = useContext(FolderContext) as FolderContextType;
    const { chatState, onOpenConversation: openConversation, closeConversation, setChatContext } = useChat();
    const [addChatDialogOpen, setAddChatDialogOpen] = useState(false);
    const [chatLabel, setChatLabel] = useState("Chat");

    const onChatDialogClose = () => {
        setAddChatDialogOpen(false);
    };

    useEffect(() => {
        setChatLabel("Chat");
    }, [chatState]);

    useEffect(() => {
        setChatContext(folder.path);
    }, [folder]);

    return (
        <>
            {chatState !== "not_supported" ? (
                <ToolbarGroup role="presentation">
                    <Menu hasIcons>
                        <MenuTrigger>
                            <ToolbarButton
                                aria-label="Chatten"
                                icon={chatState === "available" ? <ChatWarning24Regular /> : <Chat24Regular />}
                                appearance="primary"
                            >{chatLabel}</ToolbarButton>
                        </MenuTrigger>
                        <MenuPopover>
                            <MenuList>
                                <Tooltip content="Start een nieuwe chat over deze map" relationship="label">
                                    <MenuItem
                                        icon={<ChatAdd24Regular />}
                                        disabled={chatState !== "none"}
                                        onClick={() => setAddChatDialogOpen(true)}
                                    >Nieuwe chat starten</MenuItem>
                                </Tooltip>
                            </MenuList>
                            <MenuList>
                                <Tooltip content="Hervat de chat over deze map" relationship="label">
                                    <MenuItem
                                        icon={<ChatArrowBackRegular />}
                                        disabled={chatState !== "available"}
                                        onClick={() => openConversation("Chat hervatten")}
                                    >Chat hervatten</MenuItem>
                                </Tooltip>
                            </MenuList>
                            <MenuList>
                                <Tooltip content="Sluit het chatvenster" relationship="label">
                                    <MenuItem
                                        icon={<ChatDismiss24Regular />}
                                        disabled={!(chatState === "open" || chatState === "initializing")}
                                        onClick={closeConversation}
                                    >Chat sluiten</MenuItem>
                                </Tooltip>
                            </MenuList>
                        </MenuPopover>
                    </Menu>
                </ToolbarGroup>) : null
            }
            <NewChatDialog isOpen={addChatDialogOpen} onStart={openConversation} onClose={onChatDialogClose} defaultValue={`Chatten over '${folder.title}'`} />
        </>
    );
}