import { useState } from "react";

type QueryState = {
    state: "idle" | "loading" | "loaded" | "error"
    items: any[]
    errorMessage: string
};

//type queryFunctionType = <T,>() => Promise<T[]>;
type queryFunctionType = () => Promise<any[]>;

function useWebDavQuery() {
    
    const [state, setState] = useState<QueryState>({
        state: "idle",
        items: [],
        errorMessage: ""
    });

    const query = (queryFunction: queryFunctionType) => {

        if (!queryFunction) return;

        setState((s) => ({ ...s, state: "loading" }));
        
        queryFunction()
            .then((items) => {
                setState({
                    items,
                    state: "loaded",
                    errorMessage: ""
                });
            })
            .catch((error) => {
                setState({
                    items: [],
                    state: "error",
                    errorMessage: error.message || "Failed to fetch",
                });
            });
    }
    return { ...state, query }
};

export default useWebDavQuery;