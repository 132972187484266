/**
 * Format the date using the current locale.
 * 
 * @param value the date value
 * @returns the formatted date
 */
export const formatDateTime = (value: string | number | Date) => {
    const date = new Date(value);
    return date.toLocaleString();
};

const sufixes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

/**
 * Format the number of bytes to a more readable format.
 * 
 * @param bytes number of bytes
 * @returns the formatted size
 */
export const formatSize = (bytes: number) => {
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    return (!bytes && '0 Bytes') || (bytes / Math.pow(1024, i)).toFixed( bytes > 1024? 1 : 0) + " " + sufixes[i];
};